import React from 'react';

import Followers from './Followers';
import Following from './Following';

function Connection() {
    return (
        <>
            <Followers />
            <Following />
        </>
    );
}

export default Connection;